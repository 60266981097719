<template>
    <div class="flex flex-col gap-4 text-white">
        <div class="flex items-center gap-x-2">
            <Icon name="phone" class="text-sm" /><a
                href="tel:1-866-516-7247"
                class="text-inherit"
                @click="call"
                >866-516-7247</a
            >
        </div>
        <div class="flex items-center gap-x-2">
            <Icon name="envelope-solid" class="text-sm" /><a
                href="mailto:info@roastar.com"
                class="text-inherit"
                @click="email"
                >info@roastar.com</a
            >
        </div>
        <div class="flex items-center gap-x-2">
            <Icon name="comment" class="text-sm" /><button
                class="text-inherit underline hover:no-underline"
                @click="chat"
            >
                chat with us
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { openZendeskChat } from '~/utils/zendeskChat';
import { useSegmentEvent } from '~/composables/useSegment';
import { sendDataLayerEvent } from '~/composables/useDataLayer';

const call = () => {
    const segmentEvent = useSegmentEvent(
        'Click to Call',
        'Footer',
        'Click to Call',
    );

    useHead(() => ({
        script: segmentEvent,
    }));
};

const email = () => {
    const segmentEvent = useSegmentEvent(
        'Click to Email',
        'Footer',
        'Click to Email',
    );

    useHead(() => ({
        script: segmentEvent,
    }));
};

const chat = () => {
    sendDataLayerEvent('live_chat');

    const segmentEvent = useSegmentEvent(
        'Click to Chat',
        'Footer',
        'Click to Chat',
    );

    useHead(() => ({
        script: segmentEvent,
    }));

    openZendeskChat();
};
</script>
